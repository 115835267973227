<template>
    <div class="user-box">
    <div class="navTit" style="font-size: 16px;">云南省执业药师继续教育学习平台</div>
        <!-- <van-row @click="openModal">
            <van-col span="6">
                <img :src="avatarSrc">
            </van-col>
            <van-col span="18">
                <van-button round size="large">{{nickname}}</van-button>
            </van-col>
        </van-row> -->

        <van-row>
            <!-- <van-col span="6">
                <img :src="avatarSrc">
            </van-col> -->
            <van-col span="24" style="text-align: center;">
                <van-cell :title="nickname"  v-if="isLogin" label="欢迎登录系统！" />
              <van-button round size="large"  v-else @click="onLogin" >{{nickname}}</van-button>

            </van-col>

        </van-row>

        <!-- <van-grid :column-num="3" square>
          <van-grid-item icon="label-o" text="我的课程" />
          <van-grid-item icon="bill-o" text="我的笔记" />
          <van-grid-item icon="gift-card-o" text="课程答疑" />
        </van-grid> -->

        <van-grid :column-num="2">
            <van-grid-item v-for="(item, index) in squareLink" :key="index" class="squareLink" @click="handler(item.link)">
                <template #text>
                    <div class="textTitle">{{item.text}}</div>
                </template>
                <template #icon>
                    <van-icon :name="item.photo"/>
                </template>
            </van-grid-item>

        </van-grid>
<!--        <div class="horizontalLink">-->
<!--            <van-cell class="typeList" center v-for="(item, index) in horizontalLink" :key="index"-->
<!--                      @click="handler(item.link)" :value="item.desc">-->
<!--                <template #title>-->
<!--                    <span class="custom-title">{{item.text}}</span>-->
<!--                </template>-->
<!--                <template #icon>-->
<!--                    <van-icon :name="item.photo"/>-->
<!--                </template>-->
<!--                <template #right-icon>-->
<!--                    <van-icon name="arrow"/>-->
<!--                </template>-->
<!--                &lt;!&ndash; <template #value>-->
<!--                    <span class="value-title">{{item.desc}}</span>-->
<!--                </template> &ndash;&gt;-->
<!--            </van-cell>-->
<!--        </div>-->
        <!-- <div class="signOut" v-if="isLogin"> -->
        <div class="signOut"  v-if="isLogin">
            <van-button round size="large" icon="exchange" @click="loginOut">退出系统</van-button>
        </div>
    </div>
</template>

<script>
    import {courseList, GoLogin,onInfo,GoRegister } from '@/request/api'
    import {Toast } from "vant";
    let redirectUrl;
    export default {
        beforeRouteEnter(to, from, next) {
            if (to.query && to.query.redirect) {
                redirectUrl = to.query.redirect
            }
            next();
        },
        metaInfo() {
            return {
                title: this.title+'-我的', //设置页面title
            }
        },
        data() {
            return {
                title: this.getBranchCompany(),
                avatarSrc: "https://cdnoss.ksyt.com.cn/wxImages/assets/images/avatar.png",
                username: '',
                usernameRegister: '',
                password: '',
                passwordRegister: '',
                passwordRegister2: '',
                isShowModal: false,
                isShowLogin:'true',
                isShowReg: false,
                nickname: "用户登录",
                checked:'1',
                isLogin: this.getToken(),
                squareLink: [
                    {
                        text: "个人信息",
                        show: true,
                        photo: "https://cdnoss.ksyt.com.cn/wxImages/jixujiaoyu/icon-info.png",
                        link: 'handleClickLearning'
                    },
                    {
                        text: "报名缴费",
                        show: false,
                        photo: "https://cdnoss.ksyt.com.cn/wxImages/jixujiaoyu/icon-signIn.png",
                        link: 'courseSelect'
                    },
                    {
                        text: "学习中心",
                        show: false,
                        photo: "https://cdnoss.ksyt.com.cn/wxImages/jixujiaoyu/icon-learning.png",
                        link: 'courseLearning'
                    },
                    {
                        text: "学习记录",
                        show: false,
                        photo: "https://cdnoss.ksyt.com.cn/wxImages/jixujiaoyu/icon-record.png",
                        link: 'handleClickType'
                    },
                    {
                        text: "在线考试",
                        show: false,
                        photo: "https://cdnoss.ksyt.com.cn/wxImages/jixujiaoyu/icon-test.png",
                        link: 'courseOnlineExam'
                    },
                    {
                        text: "查看证书",
                        show: false,
                        photo: "https://cdnoss.ksyt.com.cn/wxImages/jixujiaoyu/icon-card.png",
                        link: 'courseViewCard'
                    },

                ],
                redirectUrl: '',
                branchName: '',
                subject_name: '',
                tab_id: 1
            }
        },
        created() {
            this.redirectUrl = this.$route.query.redirect;
            this.isShowModal = this.$route.query.isShowModal ? this.$route.query.isShowModal : false;
            // 组件刚创建的时候需要判断用户是否登录,判断localStorage有没有token值
            // 如果有就应该天上用户信息
            let token = localStorage.getItem("token")
            if (token) {
                // 表示已经有登录状态,就要渲染用户信息
                // let userInfo = JSON.parse(localStorage.getItem('userInfo'));
                // this.nickname = userInfo.nickname;
                // this.avatarSrc = userInfo.avatar;
                // this.onInfo();
            }
            this.title = this.getBranchCompany()
          this.onInfo();
        },
        methods: {
            servicesBtn(){
                let branch = this.getBranch();
                this.$router.push('/websiteInfo?branch='+branch+'&type=1')
                // this.$router.push('/freeAudition11')
                console.log("免费试听按钮被点击");
            },
            agreementBtn(){
                let branch = this.getBranch();
                this.$router.push('/websiteInfo?branch='+branch+'&type=2')
                // this.$router.push('/freeAudition11')
                console.log("免费试听按钮被点击");
            },
            openModal() {
                // 判断用户是否登录,如果登录就直接return
                // 没有登录就取反
                // let token = localStorage.getItem("token")
                // if (token) {
                //     return false;
                // }
                this.isShowModal = !this.isShowModal
            },
            onInfo() {
                let that = this;
                onInfo({})
                    .then(res => {
                        if (res.data.code == 1) {
                          this.nickname=res.data.data.name
                        }
                    })
                    .catch(err => {
                        // Toast.fail('操作错误');
                    })
            },
            clickTab(name,title) {
                this.tab_id = name
            },
            clickFn(){
                if(!this.checkRadioFlag){
                    this.checked = '';
                }
                this.checkRadioFlag = false;
            },
            changeFn(){
                this.checkRadioFlag = true;
            },
            registerFn(){
                this.isShowLogin = !this.isShowLogin;
                this.isShowReg = !this.isShowReg;
            },
            loginOut() {
                localStorage.removeItem('token');
                localStorage.removeItem('userInfo');
                // localStorage.clear();
                Toast.success('退出成功');
                console.log('测试退出');
                // console.log(this.loginTimer);
                clearInterval(this.loginTimer)
                this.loginTimer = null;
                this.$router.replace('Login')
            },
            onLogin(){
              this.$router.push({
                name: 'Login',
              })
            },
            handler(link) {
                this[link]()
            },
            handleSetting(){
                // this.$router.push('/courseHelp')
                Toast('功能暂未上线')
            },
            courseSelect(){
                this.$router.push('/courseSelect')
            },
            courseLearning(){
                this.$router.push('/learning')
            },
            courseOnlineExam(){
                this.$router.push('/courseOnlineExam')
            },
            courseViewCard(){
                this.$router.push('/courseViewCard')
            },
            courseHelp(){
                this.$router.push('/courseHelp')
            },
            handleClickLearning(){
                this.$router.push('/userInfo')
                console.log("查看用户信息");
            },
            handleClickOrderList(){
                this.$router.push('/orderList')
                console.log("搜索按钮被点击");
            },
            handleClickSchool(){
                this.$router.push('/mySchool')
                console.log("搜索按钮被点击");
            },
            handleClickQuestion(){
                this.$router.push('/Question')
                console.log("搜索按钮被点击");
            },
            handleClickProject(){
                this.$router.push('/index/project')
                console.log("搜索按钮被点击");
            },
            handleClickType(){
              this.$router.push({name:'LearningRecord'})
              console.log("学习记录");
            },

        },
    }
</script>

<style lang="less" scoped>
    .navTit {
        width: 100%;
        height: 6rem;
        line-height: 6rem;
        text-align: center;
        font-size: 2.4rem;
        background-color: #16a8ff;
        color: #fff;
        word-spacing: 0.8rem;
        font-weight: 600;
    }
    .login-modal {
        width: 100%;
        height: 110%;
        position: absolute;
        left: 0;
        top: 0;
        z-index: 99;
        bottom: -10rem;

        .login-form {
            width: 90%;
            background-color: #fff;
            padding-bottom: .2rem;
            position: absolute;
            top: 10%;
            left: 50%;
            margin-left: -45%;
            box-sizing: border-box;
            border-radius: 1rem;
        }
        .loginTitle{
            font-size: 2.4rem;
            height: 6rem;
            line-height: 6rem;
            text-align: center;
            margin: 1rem 0 2rem 0;
        }

        .close-modal {
            width: 100%;
            height: 100%;
            background: rgba(0, 0, 0, 0.5);
        }

        /* .van-form{
            width: 90%;
            position: absolute;
            top: 40vh;
            left: 50%;
            margin-left: -45%;
            padding: .1rem;
            box-sizing: border-box;
            background: #fff;
        } */
    }

    .van-row {
        height: 16rem;
        padding: 0.5rem 2rem;
        background-image: url('https://cdnoss.ksyt.com.cn/wxImages/assets/images/tbg.png');
        background-repeat: no-repeat;
        background-size: 100%;
        color: #fff;
        display: flex;
        align-items: center;

        .van-col {
            line-height: 6rem;

            img {
                width: 10rem;
                border-radius: 50%;
                display: block;
            }

            .van-button--large {
                width: 50%;
            }
        }
    }

    .van-grid {
        padding: 1rem 0.5rem;

        .van-grid-item {
            padding: 0 0.5rem;
            border-radius: 0.5rem;
        }

        .van-icon {
            font-size: 4.4rem;
            padding: 1rem;
        }

        .textTitle {
            margin-bottom: 1.5rem;
            font-size: 1.6rem;
            color: #333333;
        }
    }

    .horizontalLink {
        .van-cell {
            padding: 1rem;
        }

        .van-icon {
            font-size: 2.2rem;
            padding: 0 0.5rem;
        }

        .custom-title {
            font-size: 1.8rem;
        }

        .value-title {
            font-size: 1.8rem;
        }
    }

    .signOut {
        text-align: center;
        padding-top: 4rem;
        padding-bottom: 10rem;

        .van-button {
            width: 90%;
        }
    }
    .register{
        margin: 2rem;
        .van-button{
            border-width: 0;
            font-size: 1.8rem !important;
        }
        .text{
            color: #1989fa;
        }
    }

    .van-grid .van-grid-item{
      padding: 0!important;

    }
    .van-grid .van-grid-item .van-grid-item__content {
      padding: 1rem !important;
    }
</style>
